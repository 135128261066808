<template>
  <div>
    <gl-title :title="$route.meta.title"></gl-title>
    <div style="margin: 18px;">
      <el-button type="primary" size="mini" @click="addVisible = true"> 新增 </el-button>
    </div>
    <gl-card v-loading="mLoading">
      <el-table :data="mTableData" style="width: 100%">
        <el-table-column type="index" :index="mIndexMethod" label="序号" align="center" width="44"></el-table-column>
        <el-table-column prop="authName" label="权限组名称"></el-table-column>
        <el-table-column prop="authPerson" label="权限组人员" show-overflow-tooltip></el-table-column>
      </el-table>
      <gl-pagination
        :total="mTotal"
        :current-page="mPageNum"
        @currentChange="mHandleCurrentChange"
        @sizeChange="mHandleSizeChange">
      </gl-pagination>
    </gl-card>
    <add-role :visible.sync="addVisible" @reload="mReload"></add-role>
  </div>
</template>

<script>
import ListMixin from '@/mixins/list.mixin.js'
import addRole from './add-role.vue'
export default {
  components: {addRole},
  mixins: [ListMixin],
  data(){
    return{
      addVisible: false,
    }
  },
  mounted(){
    console.log('mixins-list:', this.mFormData)
  },
  methods: {},
}
</script>

<style>

</style>
<template>
  <el-dialog 
    title="新增"
    :visible="visible"
    :close-on-click-modal="true"
    :before-close="handleClose"
    width="600px"
  >
    <gl-wrap right="30px">
      <el-form :model="formData" ref="form" label-width="100px" width="500px" size="mini" v-loading="loading" >
        <el-form-item prop="authName" label="角色名称" verify>
          <el-input v-model="formData.authName"></el-input>
        </el-form-item>
        <el-form-item prop="roleState" label="角色说明" verify>
          <el-input v-model="formData.roleState"></el-input>
        </el-form-item>
      </el-form> 
    </gl-wrap>
    <gl-flex>
      <el-button type="primary" @click="onSubmit" >确认</el-button>
      <el-button @click="handleClose" >关闭</el-button>
    </gl-flex>
  </el-dialog>
</template>

<script>
export default {
  name: 'add-role',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return{
      formData: {},
      loading: false,
    }
  },
  methods: {
    onSubmit(){
      this.$refs.form.validate(async valid => {
        if(!valid) return false
        console.log('formData:', this.formData)
        this.loading = true
        setTimeout(() => {
          this.loading = false
          this.$message.success('操作成功')
          this.handleClose()
          this.$emit('reload')
        },1000)
      })
    },
    handleClose(){
      this.$emit('update:visible', false)
      setTimeout(() => {
        this.$refs.form.resetFields()
        this.formData = {}
      }, 100)
    },
  },
}
</script>

<style>

</style>